@import 'variables.css';
@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  /* -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

html,
body,
#app {
  width: 100vw;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;

  color: var(--color-grayscale-1);
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
}

#app,
.app-layout {
  * {
    font-family: Graphik Web, Arial;
  }
}
