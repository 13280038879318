.app-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 25px;

  @media only screen and (min-width: 1024px) {
    flex-direction: column;
    padding: 88px 25px 20px 25px;
  }

  & a {
    &:hover {
      color: var(--color-green-3);
    }
  }

  &--show-sm {
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }

  &--show-lg {
    display: none;

    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  & * {
    color: #d9dbde;
    text-decoration: none;
  }

  &__logo-area {
    grid-column-start: 1;
    grid-column-end: span col3-start;

    cursor: pointer;

    & svg {
      height: 30px;

      fill: white;

      @media only screen and (min-width: 1024px) {
        height: 74px;
      }
    }
  }

  &__nav {
    display: flex;
    margin-top: 56px;

    @media only screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }

  &__main-nav {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 24px;
    margin-top: 6px;

    & * {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }

    @media only screen and (min-width: 1024px) {
      margin-left: 180px;
    }
  }

  &__sub-nav {
    display: grid;
    grid-auto-rows: min-content;
    margin-top: 6px;
    margin-left: 83px;
    row-gap: 24px;

    & * {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }

    @media only screen and (min-width: 1024px) {
      margin-left: 142px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 343px;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 343px;
    margin-top: 56px;

    color: white;
    font-size: 12px;
    line-height: 160%;

    @media only screen and (min-width: 1024px) {
      margin-top: 0;

      font-size: 18px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    width: 343px;
    margin-top: 75px;

    color: white;
    font-size: 12px;
    line-height: 160%;

    @media only screen and (min-width: 1024px) {
      margin-top: 50px;

      font-size: 18px;
    }
  }

  &__contact-email {
    margin-top: 24px;
  }

  &__copyright {
    text-align: right;
    margin-top: 32px;
    margin-right: 20px;

    font-size: 12px;

    @media only screen and (min-width: 1024px) {
      font-size: 12px;
    }
  }

  &__contact-phone {
    color: #d9dbde !important;
  }
}
