.app-logo {
  display: flex;
  align-items: center;

  color: var(--color-grayscale-1);
  font-weight: 600;

  & svg {
    width: 26px;
    height: 30px;
    margin-right: 12px;

    fill: currentColor;
  }

  &__label {
    margin: 0;

    font-size: 20px;
    letter-spacing: 0.01em;
  }
}
