/* Global colors */
body {
  /* Grayscale */
  --color-grayscale-1: #0f1113;
  --color-grayscale-2: #efeff1;
  --color-grayscale-3: #d9dbde;
  --color-grayscale-4: #9fa2a5;
  --color-grayscale-5: #5d5f62;
  --color-grayscale-6: #2a2d30;
  --color-grayscale-7: white;
  --color-grayscale-8: black;

  /* Green */
  --color-green-1: #e6faef;
  --color-green-2: #c5f2db;
  --color-green-3: #3cd178;
  --color-green-4: #2b825a;
  --color-green-5: #1f6544;

  /* Blue */
  --color-blue-1: #e6efff;
  --color-blue-2: #b0cdff;
  --color-blue-3: #276de2;
  --color-blue-4: #0749b7;
  --color-blue-5: #0c3373;

  /* Purple */
  --color-purple-1: #f8ecfc;
  --color-purple-2: #edc8f7;
  --color-purple-3: #ab2cd1;
  --color-purple-4: #7a0c9a;
  --color-purple-5: #48095a;

  /* Orange */
  --color-orange-1: #fef2f0;
  --color-orange-2: #fbd8d0;
  --color-orange-3: #f56a48;
  --color-orange-4: #c64b2c;
  --color-orange-5: #813421;

  /* Gold */
  --color-gold-1: #fef6e9;
  --color-gold-2: #fee9cc;
  --color-gold-3: #fdb936;
  --color-gold-4: #ce9528;
  --color-gold-5: #7e551c;

  /* Red */
  --color-red-1: #fdedf1;
  --color-red-2: #f9c8d5;
  --color-red-3: #e23564;
  --color-red-4: #b61742;
  --color-red-5: #7d0e2c;
}

/* Component colors */

body {
  /* Site color mapping */
  --color-1-1: var(--color-grayscale-1);
  --color-1-2: var(--color-grayscale-2);
  --color-1-3: var(--color-grayscale-3);
  --color-1-4: var(--color-grayscale-4);
  --color-1-5: var(--color-grayscale-5);
  --color-1-6: var(--color-grayscale-6);
  --color-1-7: var(--color-grayscale-7);
  --color-2-1: var(--color-purple-1);
  --color-2-2: var(--color-purple-2);
  --color-2-3: var(--color-purple-3);
  --color-2-4: var(--color-purple-4);
  --color-2-5: var(--color-purple-5);
  --color-3-1: var(--color-green-1);
  --color-3-2: var(--color-green-2);
  --color-3-3: var(--color-green-3);
  --color-3-4: var(--color-green-4);
  --color-3-5: var(--color-green-5);

  /* Site to component color mapping */
  --btn-color-1-1: var(--color-grayscale-4);
  --btn-bg-color-1-1: #f6f6f7;
  --btn-color-2-1: #111314;
  --btn-bg-color-2-1: white;
  --btn-color-3-1: var(--color-grayscale-1);
  --btn-bg-color-3-1: var(--color-green-3);
  --btn-color-4-1: var(--color-grayscale-7);
  --btn-bg-color-4-1: var(--color-grayscale-1);
}

/* Theme colors */

/* .theme-reverse {
  --btn-color-0-0: var(--color-blue-8);
  --btn-color-1-0: var(--color-blue-6);
} */

/* iPhone X+ */
body {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}

/* Evelevations */
body {
  --elevation-drawer: 100;
  --elevation-sidebar: 1000;
  --elevation-modal: 7000;
  --elevation-global-loading: 8000;
  --elevation-toast: 9000;
}
